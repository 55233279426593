import React from "react";
import {
  SEO,
  PageLayout,
  FadeReveal,
  multipleComponentIterator,
  FullImageCard,
  InternalLink,
  FishermanIcon,
  MarkdownContent,
  Image,
  BasicCarousel,
  componentIterator,
  SocialMediaV2,
  MultipleComponentIterator,
  ReviewV2,
  CustomerReviewModal,
  HorizontalImageCard,
  BlogPostLink,
  FormattedDate,
  ExpandableText,
} from "@bluefin/components";
import { Embed, Button, Grid, Header, div, Card } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  withNamedArgs,
  getBusinessFiles,
  getComponentContentNodeContent,
} from "../utils/utils";
import { createServiceCategorySlug } from "@bluefin/gatsby-plugin-fisherman-page-creator";

export default class IndexPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      allFishermanBusinessMenuCategory,
      allFishermanBusinessFile,
      allFishermanBusinessLocation,
      allFishermanBusinessBlogPost,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout
          hero={
            <div className={"home-hero-video-container"}>
              <Embed
                url={
                  "https://www.youtube-nocookie.com/embed/9aKHAVVFiRA?mute=1&loop=1&autoplay=1&fs=0&controls=0&modestbranding=1&showinfo=0&playlist=9aKHAVVFiRA"
                }
                autoplay={true}
                defaultActive={true}
                defaultActive={true}
                className={"home-hero-video loaded"}
              />
              <div className={"hero-logo"} />
            </div>
          }
          subfooter={false}
          className={"home-page"}
        >
          <div className={"services-section"}>
            <Card.Group className={"services"} itemsPerRow={5} stackable={true}>
              <FadeReveal
                cascade={true}
                cascadeFactor={0.4}
                triggerOnce={true}
                className={"ui card"}
                disableAnimationOnMobile={true}
                noContainer={true}
                children={multipleComponentIterator({
                  iterator: allFishermanBusinessMenuCategory.nodes,
                  limit: 5,
                  components: [
                    {
                      component: (
                        <FullImageCard
                          link={true}
                          as={InternalLink}
                          event={{
                            category: "Services",
                            action: "View Category Details",
                          }}
                        />
                      ),
                      propMap: {
                        key: "_id",
                        image: withNamedArgs({
                          func: getBusinessFiles,
                          args: {
                            businessFiles: allFishermanBusinessFile.nodes,
                            numToSelect: 1,
                          },
                        }),
                        "event.label": "name",
                        to: createServiceCategorySlug,
                      },
                      children: [
                        {
                          component: <Card.Header />,
                          propMap: { content: "name", key: "name" },
                        },
                        {
                          component: <FishermanIcon iconName={"arrow-right"} />,
                        },
                      ],
                    },
                  ],
                })}
              />
            </Card.Group>
            <Button
              primary={true}
              to={"/services/"}
              event={{ category: "Services", action: "View All Services" }}
              as={InternalLink}
            >
              See All Services
            </Button>
          </div>
          <div className={"about-section"}>
            <Grid stackable={true} doubling={true} columns={2}>
              <Grid.Column width={8} className={"markdown-column"}>
                <FadeReveal duration={1250} triggerOnce={true}>
                  <MarkdownContent
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentId: "about_section_description",
                    })}
                  />
                </FadeReveal>
              </Grid.Column>
              <Grid.Column width={8} className={"image-column"}>
                <FadeReveal duration={1250} triggerOnce={true} delay={450}>
                  <Image
                    className={"image"}
                    src={getComponentFiles({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "Content.Image",
                      componentIdentifier: "about_section_image",
                      numToSelect: 1,
                    })}
                  />
                </FadeReveal>
              </Grid.Column>
            </Grid>
          </div>
          <div className={"trusted-by-section"}>
            <Grid stackable={true} columns={2}>
              <Grid.Column width={5} className={"copy-column"}>
                <Header
                  as={"h2"}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "trusted_by_section_header",
                    defaultValue: "TRUSTED BY:",
                  })}
                />
              </Grid.Column>
              <Grid.Column width={11} className={"images-column"}>
                <BasicCarousel
                  className={"image-gallery"}
                  size={4}
                  displayIndicators={false}
                  displayPlayPauseButton={true}
                  autoScroll={true}
                  animation={{
                    name: "blur",
                    duration: 200,
                    transitionProps: {
                      preAnimating: { opacity: 0.99, blur: "2px" },
                      animating: { opacity: 0.99, blur: "2px" },
                    },
                  }}
                  mobile={{ size: 2 }}
                  items={componentIterator({
                    iterator: getComponentFiles({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "ImageCollection",
                      componentIdentifier: "trusted_by_image_gallery",
                    }),
                    component: (
                      <Image
                        background={true}
                        className={"gallery-image-item"}
                      />
                    ),
                    propMap: { src: "__all__" },
                  })}
                />
              </Grid.Column>
            </Grid>
          </div>
          <Grid
            stackable={true}
            className={"component-section-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={14} textAlign={"center"}>
              <div className={"robert-image-container"}>
                <Image
                  size={"large"}
                  centered={true}
                  src={
                    "https://fisherman.gumlet.io/public/ecced18a-ad62-4442-a99f-d512a28adaf5/Melissa__Bob_uT7kgG9.JPG"
                  }
                />
              </div>
              <Button
                to={"/team/"}
                primary={true}
                event={{ category: "Team", action: "View All Team Members" }}
                as={InternalLink}
              >
                Meet Our Team
              </Button>
            </Grid.Column>
          </Grid>
          <div className={"image-gallery-section"}>
            <Header
              as={"h2"}
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "image_section_header",
                defaultValue: "WE'RE SOCIAL",
              })}
            />
            <MarkdownContent
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "image_section_description",
              })}
            />
            <SocialMediaV2>
              <MultipleComponentIterator
                iterator={fishermanBusiness.socialMedia}
                components={[
                  {
                    component: <SocialMediaV2.Platform />,
                    propMap: { key: "_id", url: "link", platform: "type" },
                    children: [
                      {
                        component: <SocialMediaV2.Icon />,
                        propMap: { icon: "type" },
                      },
                      {
                        component: <SocialMediaV2.Handle />,
                        propMap: { link: "link" },
                      },
                    ],
                  },
                ]}
              />
            </SocialMediaV2>
            <div
              className={"elfsight-app-6d656352-6eeb-4213-b112-ec0763a7fefc"}
              data-elfsight-app-lazy={true}
            />
          </div>
          <div className={"reviews-section"}>
            <BasicCarousel
              size={3}
              displayIndicators={false}
              stepSize={1}
              autoScroll={false}
              displayPlayPauseButton={false}
              animation={{ name: "blur", duration: 160 }}
              items={multipleComponentIterator({
                iterator: fishermanBusiness.reviews,
                components: [
                  {
                    component: <ReviewV2 />,
                    propMap: { key: "_id" },
                    children: [
                      { component: <FishermanIcon iconName={"quote-left"} /> },
                      {
                        component: <ReviewV2.Text charLimit={360} />,
                        propMap: { text: "text" },
                      },
                      {
                        component: <ReviewV2.Line />,
                        children: [
                          {
                            component: <ReviewV2.Rating />,
                            propMap: { rating: "rating" },
                          },
                          {
                            component: <ReviewV2.RatingLabel />,
                            propMap: { rating: "rating" },
                          },
                        ],
                      },
                      {
                        component: <ReviewV2.Line />,
                        children: [
                          {
                            component: <ReviewV2.Author />,
                            propMap: { author: "author" },
                          },
                          {
                            component: <ReviewV2.Source />,
                            propMap: { source: "source", link: "link" },
                          },
                        ],
                      },
                    ],
                  },
                ],
              })}
            />
            <div className={"submit-review-button-container"}>
              <CustomerReviewModal
                businessId={fishermanBusiness._id}
                locations={allFishermanBusinessLocation.nodes}
                businessType={fishermanBusiness.type}
              />
            </div>
          </div>
          <div className={"image-gallery-section gallery-grid"}>
            <BasicCarousel
              className={"image-gallery"}
              size={4}
              grouping={2}
              displayIndicators={true}
              displayPlayPauseButton={true}
              autoScroll={true}
              tablet={{}}
              mobile={{}}
              animation={{
                name: "blur",
                duration: 200,
                transitionProps: {
                  preAnimating: { opacity: 0.99, blur: "2px" },
                  animating: { opacity: 0.99, blur: "2px" },
                },
              }}
              items={componentIterator({
                iterator: getComponentFiles({
                  components: fishermanBusinessWebsitePage.components,
                  componentName: "ImageCollection",
                  componentIdentifier: "image_gallery",
                }),
                component: (
                  <Image background={true} className={"gallery-image-item"} />
                ),
                propMap: { src: "__all__" },
              })}
            />
          </div>
          <div className={"blogs-section"}>
            <Header
              as={"h2"}
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "blog_section_header",
                defaultValue: "LATEST ARTICLES",
              })}
            />
            <MarkdownContent
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "blog_section_description",
              })}
            />
            <Card.Group
              className={"featured-blogs"}
              itemsPerRow={2}
              stackable={true}
            >
              <MultipleComponentIterator
                iterator={allFishermanBusinessBlogPost.nodes}
                limit={2}
                components={[
                  {
                    component: <HorizontalImageCard imagePosition={"left"} />,
                    propMap: { key: "_id", image: "gatsbyHeroImage" },
                    children: [
                      {
                        component: <BlogPostLink />,
                        propMap: { key: "_id", slug: "slug", title: "title" },
                        children: [
                          {
                            component: <Card.Header className={"title"} />,
                            propMap: { content: "title" },
                          },
                          {
                            component: <FormattedDate outputFormat={"PPP"} />,
                            propMap: { date: "publishedAt" },
                          },
                          {
                            component: (
                              <ExpandableText
                                charLimit={160}
                                disableExpanding={true}
                              />
                            ),
                            propMap: { text: "summary" },
                          },
                          {
                            component: (
                              <FishermanIcon iconName={"arrow-right"} />
                            ),
                          },
                        ],
                      },
                    ],
                  },
                ]}
              />
            </Card.Group>
            <Button
              to={"/blog/"}
              primary={true}
              as={InternalLink}
              event={{ category: "Blog", action: "View All Blog Posts" }}
            >
              See All Articles
            </Button>
          </div>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      socialMedia {
        _id
        link
        type
      }
      reviews {
        _id
        author
        text
        source
        rating
        link
      }
      _id
      type
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Home" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        contentNodes {
          content
        }
      }
    }
    allFishermanBusinessMenuCategory(
      sort: { fields: [isFeatured, order], order: [DESC, ASC] }
    ) {
      nodes {
        description
        name
        _id
        files
      }
    }
    allFishermanBusinessFile {
      nodes {
        _id
        file
        description
        gatsbyFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
    allFishermanBusinessLocation {
      nodes {
        _id
        street
        city
        name
        phoneNumber
        primaryLocation
      }
    }
    allFishermanBusinessBlogPost(filter: { destination: { eq: "BLOG" } }) {
      nodes {
        _id
        title
        slug
        author {
          firstName
          lastName
          avatar
          email
        }
        summary
        content
        heroImage
        gatsbyHeroImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        publishedAt
      }
    }
  }
`;
